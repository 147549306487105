<template>
	<div class="b2c-page-bg">
		<div class="b2c-pc-header">
			<h2><a @click="$router.push({ path: '/itshop/tai-chinh-bao-hiem' });">
				<feather-icon size="24" icon="ChevronLeftIcon"/>
				PJICO - Lựa chọn Sản phẩm bảo hiểm
			</a>
			</h2>
		</div>
		<b-card class="mr-auto ml-auto" style="width: 650px;">
			<div class="mb-2">
				<p class="text-center pt-2 pb-3" style="font-size: 18px; font-weight: 700; font-style: normal;">Lựa chọn sản phẩm bảo hiểm</p>
				<div class=" d-flex align-items-center mlx-27 mb-1" style="margin-left: 37%;">
					<b-button @click=" changeTab('motorcycle')" :style="type==='motorcycle' ? 'background: #ed1f24 !important; border-color: #ED1F24 !important;':''" style="border-radius: 17px !important; width: 66.48px !important; height: 66.48px !important;">
						<img style="margin-left: -10px;" alt="motorcycle_insurance" src="../../../assets/images/shop/motorcycle_insurance.png"/>
					</b-button>
					<b-button @click="changeTab('auto')" :style="type==='auto' ? 'background: #ed1f24 !important; border-color: #ED1F24 !important;':''" style="border-radius: 17px !important; width: 66.48px !important; height: 66.48px !important;" class="ml-4">
						<img style="margin-left: -10px;" alt="auto_insurance" src="../../../assets/images/shop/auto_insurance.png"/>
					</b-button>
        </div>
				<div class=" d-flex align-items-center text-center mlx-25" style="margin-left: 35%;">
					<p>Bảo hiểm <br/> TNDS xe máy</p>
					<p class="ml-3">Bảo hiểm <br/> TNDS Ô tô</p>
				</div>
			</div>
			<div class="b2c-pc-header-content" v-if="type==='motorcycle'">
				<div class="p-2">
					<p style="font-size: 18px; font-weight: 700; font-style: normal;" class="mb-2">Bảo hiểm trách nhiệm dân sự xe máy</p>
					<b-row class="container-body">
						<b-col cols="9" class="mx-100">
							<p class="mr-4">Chỉ từ 55,000 đồng/năm cùng quyền lợi bảo hiểm lên đến 150 triệu đồng, Bảo hiểm trách nhiệm dân sự bắt buộc của chủ xe máy không chỉ là giấy tờ quan trọng luôn phải mang theo khi tham gia giao thông mà còn mang đến sự bảo vệ tài chính cho chủ phương tiện khi không may xảy ra tai nạn.</p>
							<p @click="$bvModal.show('modal-motorcycle')" style="color: #ED1F24; text-decoration: underline; cursor: pointer;" class="mb-2 mbx-0"><b>Xem thêm</b></p>
						</b-col>
						<b-col cols="3" class="mx-100 dxl-flex">
							<p class="mb-5 pt-2 mbx-0">Từ <span style="color: #ED1F24; font-size: 18px;"><b>55,000 VND</b></span></p>
							<b-button class="b2c-pc-header-content-btn mt-1" @click="getMotorcycle">Đặt mua</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
			<div class="b2c-pc-header-content" v-if="type==='auto'">
				<div class="p-2">
					<p style="font-size: 18px; font-weight: 700; font-style: normal;" class="mb-1">Bảo hiểm trách nhiệm dân sự Ô TÔ</p>
					<b-row class="container-body">
						<b-col cols="9" class="mx-100">
							<p class="mr-4">Chỉ từ 1,300 đồng/ngày, tham gia Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe ô tô là bạn đã chấp hành đúng chủ trường, chính sách pháp luật của Nhà nước và luật giao thông đường bộ. Trong trường hợp vi phạm, bạn có thể bị phạt chế tài từ 400,000 - 600,000 đồng do không có bảo hiểm khi bị kiểm tra. Hơn nữa, ngoài sản phẩm bảo hiểm bắt buộc, PJICO còn cung cấp thêm các gói bảo hiểm tự nguyện dành cho lái xe, phụ xe và người ngồi trên xe ô tô với mức quyền lợi lên tới 100 triệu đồng/người/vụ với mức phí chỉ từ 20,000 đồng.</p>
							<p @click="$bvModal.show('modal-car')" style="color: #ED1F24; text-decoration: underline; cursor: pointer;" class="mb-2 mbx-0"><b>Xem thêm</b></p>
						</b-col>
						<b-col cols="3" class="mx-100 dxl-flex">
							<p class="mb-5 mt-5 mbx-0 mtx-0">Từ <span style="color: #ED1F24; font-size: 18px;"><b>437,000 VND</b></span></p>
							<b-button class="b2c-pc-header-content-btn mtx-0" style="margin-top: 50%;" @click="getAuto">Đặt mua</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</b-card>
		<b-modal
			size="lg"
			body-class="p-2"
			hide-header
			hide-footer
			id="modal-car"
			title=""
			>
			<div class="p-3">
				<p style="font-size: 18px; font-weight: 700; font-style: normal;" class="mb-2">BẢO HIỂM TRÁCH NHIỆM DÂN SỰ BẮT BUỘC CỦA CHỦ XE Ô TÔ</p>
				<div class="pre-scrollable">
					<p class="mb-2">Chỉ từ 1,300 đồng/ngày, tham gia Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe ô tô là bạn đã chấp hành đúng chủ trường, chính sách pháp luật của Nhà nước và luật giao thông đường bộ. Trong trường hợp vi phạm, bạn có thể bị phạt chế tài từ 400,000 - 600,000 đồng do không có bảo hiểm khi bị kiểm tra. Hơn nữa, ngoài sản phẩm bảo hiểm bắt buộc, PJICO còn cung cấp thêm các gói bảo hiểm tự nguyện dành cho lái xe, phụ xe và người ngồi trên xe ô tô với mức quyền lợi lên tới 100 triệu đồng/người/vụ với mức phí chỉ từ 20,000 đồng.</p>
					<p style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;" class="mb-2">Mô tả sản phẩm</p>
					<p class="mb-2">Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới đối với bên thứ ba là loại hình mà tất cả các cá nhân, tổ chức (bao gồm cá nhân, tổ chức nước ngoài) sở hữu xe cơ giới tại Việt Nam đều phải tham gia theo quy định của Nhà nước. Việc tham gia nhằm đảm bảo quyền lợi cho bên thứ ba trong trường hợp nếu chẳng may chủ xe, lái xe gây thiệt hại cho họ.</p>
					<p class="mb-2">Hiểu một cách đơn giản, trong trường hợp có tai nạn xảy ra, các công ty bảo hiểm sẽ sử dụng số tiền bảo hiểm thu được từ số đông đề bồi thường cho số ít người bị tai nạn. Điều này một phần giúp hỗ trợ tài chính cho người gây ra tai nạn để bồi thường cho người bị thiệt hại</p>
					<p class="mb-2" style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;">Đối tượng sản phẩm</p>
					<p class="mb-2">Trách nhiệm dân sự của chủ xe đối với người thứ ba của chủ xe máy kể cả người nước ngoài sử dụng xe cơ giới hoạt động trên lãnh thổ nước Cộng hòa Xã hội Chủ nghĩa Việt Nam</p>
					<p class="mb-2" style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;">Quyền lợi sản phẩm</p>
					<div class="mb-3">
						<table class="table table-bordered table">
							<thead>
								<tr>
									<th class="title-table">PHẠM VI BẢO HIỂM</th>
									<th class="title-table">MỨC TRÁCH NHIỆM</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Thiệt hại của nạn nhân dẫn đến tử vọng, thương tật thân thể do tai nạn của xe cơ giới gây ra</th>
									<td>Tối đa 150 triệu đồng/người/vụ</td>
								</tr>
								<tr>
									<th>Thiệt hại về tài sản của nạn nhân do tai nạn của xe cơ giới gây ra</th>
									<td>Tối đa 50 triệu đồng/người/vụ</td>
								</tr>
								<tr>
									<th>Thiệt hại của lái xe, phụ xe, người ngồi trên xe dẫn đến tử vong, thương tật thân thể do tai nạn của xe cơ giới gây ra</th>
									<td>Căn cứ theo lựa chọn của KH</td>
								</tr>
							</tbody>
						</table>
      		</div>
					<p class="mb-2" style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;">3 LÝ DO NÊN MUA BẢO HIỂM TNDS BẮT BUỘC</p>
					<div class="px-3">
						<p class="mb-2">
							1. Tham gia Bảo hiểm TNDS Bắt buộc là tuân thủ quy định của Luật Giao thông đường bộ.
							Giấy chứng nhận bảo hiểm trách nhiệm dân sự là một trong các loại giấy tờ bắt buộc người lái xe phải mang theo khi điều khiển phương tiện tham gia giao thông. (Điểm b, Khoản 4, Điều 21 quy định như sau: Phạt tiền từ 400,000 đồng đến 600,000 đồng đối với một trong các hành vi vi phạm sau đây: Người điều khiển ô tô, máy kéo và các loại xe tương tự xe ô tô không có hoặc không mang theo Giấy chứng nhận bảo hiểm trách nhiệm dân sự của chủ xe cơ giới còn hiệu lực).
						</p>
						<p class="mb-2">
							2. Tham gia Bảo hiểm TNDS Bắt buộc là bạn đang mua sự bảo đảm cho chính bản thân và hành trình của mình trước những rủi ro không may gặp phải.
							Khi tham gia giao thông, bạn luôn cầu mong 2 chữ “bình yên” trong suốt hành trình, tuy nhiên những sự cố không mong muốn luôn rình rập và xảy ra bất kỳ lúc nào, những sự việc đáng tiếc đó gây thiệt hại cho của cải, vật chất cũng như người ngồi trên xe. Tham gia bảo hiểm tại PJICO để đảm bảo tài sản của bạn ngay hôm nay.
						</p>
						<p class="mb-2">
							3. Tham gia Bảo hiểm ô tô bắt buộc là bạn đang góp phần nhân rộng giá trị nhân văn tốt đẹp của bảo hiểm.
							Nguyên lý của bảo hiểm đó là “Lấy số đông bù cho số ít kém may mắn”, trong trường hợp không may tai nạn xảy ra, các công ty bảo hiểm sẽ sử dụng số tiền bảo hiểm thu được từ số đông để bồi thường cho số ít người bị tai nạn. Điều đó giúp cho một số người vì một lý do nào đó gây tai nạn không phải bỏ trốn, có trách nhiệm với tai nạn đã xảy ra theo mức trách nhiệm của bảo hiểm đã tham gia, và không bị truy cứu hình sự vì mất khả năng bồi thường cho chủ thể khác. Trong trường hợp này, bạn sẽ hài lòng vì tai nạn không xảy ra với mình mà bên cạnh đó, chính bạn đã góp phần giúp đỡ người không may mắn gặp rủi ro.
						</p>
					</div>
					<p style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;" class="mb-2">LIÊN HỆ NGAY HOTLINE 1900 54 54 55 (1.000đ/phút) ĐỂ ĐƯỢC HỖ TRỢ!</p>
				</div>
			</div>
			<div class="col-12 text-center pt-0 mt-0 mb-2">
				<b-button @click="$bvModal.hide('modal-car')" style="width: 140px !important; height: 40px !important; font-size: 14px !important; color: #fff !important; border-radius: 16px !important; background: #ED1F24 !important; border: none !important;">Đóng</b-button>
        </div>
		</b-modal>
		<b-modal
			size="lg"
			body-class="p-2"
			hide-header
			hide-footer
			id="modal-motorcycle"
			title=""
			>
			<div class="p-3">
				<p style="font-size: 18px; font-weight: 700; font-style: normal;" class="mb-2">BẢO HIỂM TRÁCH NHIỆM DÂN SỰ BẮT BUỘC CỦA CHỦ XE MÁY</p>
				<div class="pre-scrollable">
					<p class="mb-2">Chỉ từ 55,000 đồng/năm cùng quyền lợi bảo hiểm lên đến 150 triệu đồng, Bảo hiểm trách nhiệm dân sự bắt buộc của chủ xe máy không chỉ là giấy tờ quan trọng luôn phải mang theo khi tham gia giao thông mà còn mang đến sự bảo vệ tài chính cho chủ phương tiện khi không may xảy ra tai nạn. Hơn nữa, chủ xe còn có thể bị phạt chế tài từ 100,000 - 200,000 đồng do không có bảo hiểm khi bị kiểm tra. Ngoài ra, PJICO còn cung cấp thêm các gói bảo hiểm tự nguyện cho người ngồi trên xe với mức phí tăng thêm chỉ từ 20,000 đồng.</p>
					<p style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;" class="mb-2">Mô tả sản phẩm</p>
					<p class="mb-2">Bảo hiểm bắt buộc trách nhiệm dân sự của chủ xe cơ giới đối với bên thứ ba là loại hình mà tất cả các cá nhân, tổ chức (bao gồm cá nhân, tổ chức nước ngoài) sở hữu xe cơ giới tại Việt Nam đều phải tham gia theo quy định của Nhà nước. Việc tham gia nhằm đảm bảo quyền lợi cho bên thứ ba trong trường hợp nếu chẳng may chủ xe, lái xe gây thiệt hại cho họ.</p>
					<p class="mb-2">Hiểu một cách đơn giản, trong trường hợp có tai nạn xảy ra, các công ty bảo hiểm sẽ sử dụng số tiền bảo hiểm thu được từ số đông đề bồi thường cho số ít người bị tai nạn. Điều này một phần giúp hỗ trợ tài chính cho người gây ra tai nạn để bồi thường cho người bị thiệt hại</p>
					<p class="mb-2" style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;">Đối tượng sản phẩm</p>
					<p class="mb-2">Trách nhiệm dân sự của chủ xe đối với người thứ ba của chủ xe máy kể cả người nước ngoài sử dụng xe cơ giới hoạt động trên lãnh thổ nước Cộng hòa Xã hội Chủ nghĩa Việt Nam</p>
					<p class="mb-2" style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;">Quyền lợi sản phẩm</p>
					<div class="mb-3">
						<table class="table table-bordered table">
							<thead>
								<tr>
									<th class="title-table">PHẠM VI BẢO HIỂM</th>
									<th class="title-table">MỨC TRÁCH NHIỆM</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Thiệt hại của nạn nhân dẫn đến tử vọng, thương tật thân thể do tai nạn của xe cơ giới gây ra</th>
									<td>Tối đa 150 triệu đồng/người/vụ</td>
								</tr>
								<tr>
									<th>Thiệt hại về tài sản của nạn nhân do tai nạn của xe cơ giới gây ra</th>
									<td>Tối đa 50 triệu đồng/người/vụ</td>
								</tr>
								<tr>
									<th>Thiệt hại của lái xe, phụ xe, người ngồi trên xe dẫn đến tử vong, thương tật thân thể do tai nạn của xe cơ giới gây ra</th>
									<td>Căn cứ theo lựa chọn của KH</td>
								</tr>
							</tbody>
						</table>
      		</div>
					<p class="mb-2" style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;">3 LÝ DO NÊN MUA BẢO HIỂM TNDS BẮT BUỘC</p>
					<div class="px-3">
						<p class="mb-2">
							1. Tham gia Bảo hiểm TNDS Bắt buộc là tuân thủ quy định của Luật Giao thông đường bộ.
							Giấy chứng nhận bảo hiểm trách nhiệm dân sự là một trong các loại giấy tờ bắt buộc người lái xe phải mang theo khi điều khiển phương tiện tham gia giao thông. (Điểm b, Khoản 4, Điều 21 quy định như sau: Phạt tiền từ 400,000 đồng đến 600,000 đồng đối với một trong các hành vi vi phạm sau đây: Người điều khiển ô tô, máy kéo và các loại xe tương tự xe ô tô không có hoặc không mang theo Giấy chứng nhận bảo hiểm trách nhiệm dân sự của chủ xe cơ giới còn hiệu lực).
						</p>
						<p class="mb-2">
							2. Tham gia Bảo hiểm TNDS Bắt buộc là bạn đang mua sự bảo đảm cho chính bản thân và hành trình của mình trước những rủi ro không may gặp phải.
							Khi tham gia giao thông, bạn luôn cầu mong 2 chữ “bình yên” trong suốt hành trình, tuy nhiên những sự cố không mong muốn luôn rình rập và xảy ra bất kỳ lúc nào, những sự việc đáng tiếc đó gây thiệt hại cho của cải, vật chất cũng như người ngồi trên xe. Tham gia bảo hiểm tại PJICO để đảm bảo tài sản của bạn ngay hôm nay.
						</p>
						<p class="mb-2">
							3. Tham gia Bảo hiểm ô tô bắt buộc là bạn đang góp phần nhân rộng giá trị nhân văn tốt đẹp của bảo hiểm.
							Nguyên lý của bảo hiểm đó là “Lấy số đông bù cho số ít kém may mắn”, trong trường hợp không may tai nạn xảy ra, các công ty bảo hiểm sẽ sử dụng số tiền bảo hiểm thu được từ số đông để bồi thường cho số ít người bị tai nạn. Điều đó giúp cho một số người vì một lý do nào đó gây tai nạn không phải bỏ trốn, có trách nhiệm với tai nạn đã xảy ra theo mức trách nhiệm của bảo hiểm đã tham gia, và không bị truy cứu hình sự vì mất khả năng bồi thường cho chủ thể khác. Trong trường hợp này, bạn sẽ hài lòng vì tai nạn không xảy ra với mình mà bên cạnh đó, chính bạn đã góp phần giúp đỡ người không may mắn gặp rủi ro.
						</p>
					</div>
					<p style="font-size: 14px; font-weight: 600; font-style: normal; color: #787878;" class="mb-2">LIÊN HỆ NGAY HOTLINE 1900 54 54 55 (1.000đ/phút) ĐỂ ĐƯỢC HỖ TRỢ!</p>
				</div>
			</div>
			<div class="col-12 text-center pt-0 mt-0 mb-2">
				<b-button @click="$bvModal.hide('modal-motorcycle')" style="width: 140px !important; height: 40px !important; font-size: 14px !important; color: #fff !important; border-radius: 16px !important; background: #ED1F24 !important; border: none !important;">Đóng</b-button>
        </div>
		</b-modal>
	</div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {
  BCard,
  BCardText,
  BCardHeader,
  BFormInput,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BModal,
	BButton,
} from "bootstrap-vue";
export default {
	components: {
		BCard,
		BButton,
		BCardText,
		BCardHeader,
		BFormInput,
		BCardBody,
		BCardFooter,
		BRow,
		BCol,
		BModal,
		FeatherIcon,
	},
	data() {
		return {
			type: 'motorcycle',
			motor:[],
			car: [],
		};
	},
	methods: {
		changeTab(type){
			this.type = type
			localStorage.setItem('VehicleType', type)
		},
		getMotorcycle() {
			this.loading(true);
			useJwt.get("pjico/meta/MotorType").then(response => {
        console.log('res', response);
				this.$router.push({name: 'open-credit-card-motorcycle'});
				this.motor = response.data.result;
      }).catch(err => { 
				this.loading(false);
				this.$toast.err(
          "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      })
		},
		getAuto() {
			this.loading(true);
			useJwt.get("pjico/meta/CarType").then(response => {
        console.log('oto', response);
				this.$router.push({name: 'open-credit-card-car'});
				this.car = response.data.result;
				
      }).catch(err => { 
				this.loading(false);
				this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      })
		},
	}
}
</script>

<style lang="scss" scoped>
.b2c-pc-header-content-btn {
	width: 140px !important;
	height: 40px !important;
	font-size: 14px !important;
	color: #fff !important;
	border-radius: 16px !important;
	background: #ED1F24 !important;
	border: none !important;
}
.title-table {
	font-size: 1.4rem;
	text-align: center;
	font-weight: 700;
}
td {
	line-height: 2rem;
}
table {
	font-size: 1.4rem;
}
th {
	line-height: 20px;
	color: #424242;
	padding: 0.5rem 1rem;
	width: 50%;
}
.pre-scrollable {
  max-height: 200px;
  overflow-y: scroll;
}
p {
	font-size: 14px;
}
.b2c-pc-header-content {
	background: #F3F3F3;
	border-radius: 16px;
}
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-pc-header {
	margin-left: auto;
	margin-right: auto;
	height: 70px;
	width: 100%;
	min-height: 150px;
}
.b2c-pc-header h2 {
	border-bottom: 1px solid #dadada;
	font-size: 24px;
	font-weight: 700;
	line-height: 69px;
	padding-left: 15%;
	color: #424242;
	width: 100%;
}
.card {
	padding: 0;
}
@media screen and (max-width: 450px) {
	.dxl-flex {
		display: flex;
	}
	.mbx-0 {
		margin-bottom: 0 !important;
	}
	.mtx-0 {
		margin-top: 0 !important;
	}
	.mx-100 {
		max-width: 100% !important;
	}
	.container-body {
		display: block !important;
	}
	.b2c-pc-header {
		min-height: 70px;
	}
	.mlx-27 {
		margin-left: 27% !important;
	}
	.mlx-25 {
		margin-left: 25% !important;
	}
	.b2c-pc-header h2 {
		padding-left: 0;
		font-size: 14px;
	}
	.card {
		width: 380px !important;
	}
	.b2c-pc-header-content-btn {
    margin-left: 15%;
	}
}
</style>